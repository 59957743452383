:root {
    --font-family--main: 'Montserrat';
    --text-size--1: 10px;
    --text-size--2: 12px;
    --text-size--3: 14px;
    --text-size--4: 16px;
    --text-size--5: 18px;
    --text-size--6: 20px;
    --text-size--7: 22px;
    --text-size--8: 24px;
    --text-size--9: 56px;

    @media screen and (min-width: $size--laptop) {
        --text-size--5: 20px;
        --text-size--6: 24px;
        --text-size--7: 28px;
    }

    @media screen and (min-width: $size--4k) {
        --text-size--1: 12px;
        --text-size--2: 14px;
        --text-size--3: 16px;
        --text-size--4: 18px;
        --text-size--5: 22px;
        --text-size--6: 28px;
        --text-size--7: 34px;
    }
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-weight: 300;
    position: relative;
    -webkit-tap-highlight-color: transparent;

    &.disabled {
        pointer-events: none;
        user-select: none;
    }
}

html,
body {
    font-family: var(--font-family--main);
}

html,
body,
#root,
.app_container {
    width: 100%;
    height: 100%;
}

body {
    margin: 0;
    display: flex;
    font-size: var(--text-size--3);
    color: var(--text-color--first);
}

input {
    font-family: var(--font-family--main);
}