.account {
    .account__user_info {

        // background: var(--bg-color--white);
        // border-radius: 16px;
        .user_info__default {
            width: 4.57em;
            filter: invert(53%) sepia(92%) saturate(1346%) hue-rotate(149deg) brightness(91%) contrast(103%);
        }

        .account__user_photo {
            width: 6.57em;
            height: 6.57em;
            border-radius: 8px;
            object-fit: cover;
        }

        .account__user_input_photo {
            width: 6.57em;
            height: 6.57em;
            position: absolute;
            z-index: 2;
            opacity: 0;
        }
    }

    .account__img_container {
        cursor: pointer;
        justify-items: center;
        border-radius: 8px;
        background-color: var(--bg-color--blue-alter);
    }

    .account__rss_logo {
        filter: invert(25%) sepia(99%) saturate(1626%) hue-rotate(179deg) brightness(99%) contrast(102%);
        padding: 6px;
        width: 44px;
        height: 44px;
        cursor: pointer
    }

    // .account__user_points {
    // background: var(--bg-color--blue-alter);
    // border-radius: 16px;
    // }

    .account__logout {
        border: solid 2px var(--border-color--white);
        // background: transparent;
        margin-top: 0.46em;
    }

    .account__total_bold {
        font-weight: bold;
        font-size: 16px;
    }
}