.loading_container {
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    z-index: 10;

    .circle {
        padding: 0.9em;
        border: solid 0.5em;
        border-radius: 50%;
        animation: rotate linear infinite 0.8s;
    }
}


@keyframes rotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}