.button {
    font-weight: bold;
    padding: 18px 22px;
    border: none; //2px solid var(--border-color--second);
    border-radius: 35px;
    background-color: var(--bg-color--first);
    font-size: 16px;
    cursor: pointer;
    color: var(--text-color--white);

    &:hover {
        background-color: var(--bg-color--second);
    }
}