.character_field {
    &.status--normal {
        .character_field__input {
            border-color: var(--border-color--second);
            &:focus {
                border-color: var(--border-color--first);
            }
        }
    }
    &.status--error {
        .character_field__input {
            border-color: var(--border-color--error-alter);
        }
    }
}