.message_box_modal {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(50, 50, 50, .1);
    z-index: 1000;

    .message_box {
        max-width: 40em;
        max-height: 60vh;
        border-radius: 1em;
        background-color: white;
        box-shadow: 0px 0px 12px -2px grey;
        margin: 0px 10px;
        
        p.text {
            text-align: center;
            color: var(--text-color--first);
        }
        .message_box__title {
            font-size: 16px;
        }
        .message_box__content {
            overflow: auto;
        }

        .message_box__buttons {}
    }
}